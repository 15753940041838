var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userData.user_profile_id != 4)?_c('b-nav-item',{on:{"click":function($event){_vm.newnessReportModal = true}}},[_c('feather-icon',{attrs:{"size":"21","icon":"AlertTriangleIcon"}}),_vm._v(" Novedad ")],1):_vm._e(),_c('b-modal',{attrs:{"title":"Reportar novedad","hide-footer":"","no-close-on-backdrop":"","size":"lg"},model:{value:(_vm.newnessReportModal),callback:function ($$v) {_vm.newnessReportModal=$$v},expression:"newnessReportModal"}},[_c('b-card-text',[_vm._v(" Ingresa el serial al cual se le va a reportar una novedad ")]),_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formSearchProducts"},[_c('b-form',{staticClass:"mb-1",on:{"submit":function($event){$event.preventDefault();return _vm.searchProduct($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Serial con novedad","label-for":"serial"}},[_c('validation-provider',{attrs:{"name":"Serial con novedad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"serial"},model:{value:(_vm.serial),callback:function ($$v) {_vm.serial=$$v},expression:"serial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"outline-primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Buscar ")])],1)],1)],1),_c('b-col',[(_vm.product)?_c('div',[_c('h4',{staticClass:"text-primary"},[_vm._v("Resultado de busqueda")]),_c('h5',{staticClass:"text-info"},[_vm._v(_vm._s(_vm.product.product_enlistment ? 'En alistamiento' : (_vm.product.product_inventory ? 'En inventario' : '')))]),_c('h5',[_vm._v("Serial: "+_vm._s(_vm.product.serial))]),_c('h5',[_vm._v("Tipo de producto: "+_vm._s(_vm.product.product_type.name))]),_c('h5',[_vm._v("Número de transporte: "+_vm._s(_vm.product.number_order))]),_c('h5',[_vm._v("Novedad: "),_c('span',{class:_vm.product.no_dispatch == 1 ? 'text-danger' : 'text-primary'},[_vm._v(_vm._s(_vm.product.newness ? _vm.product.newness.newness_type.name : 'Sin novedad'))])])]):_vm._e()])],1),(_vm.product)?_c('div',{staticClass:"mt-1"},[_c('search-location-products-card',{attrs:{"product":_vm.product}})],1):_vm._e(),(_vm.product)?_c('div',[(!_vm.product.product_enlistment)?_c('div',[_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text text-primary"},[_vm._v(" Agregar novedad ")])]),_c('validation-observer',{ref:"formNewnessReport"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveNewness($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Serial *","label-for":"serial"}},[_c('validation-provider',{attrs:{"name":"Serial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"serial"},model:{value:(_vm.serial),callback:function ($$v) {_vm.serial=$$v},expression:"serial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4100299324)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Novedad *","label-for":"newness"}},[_c('validation-provider',{attrs:{"name":"Novedad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"newness","options":_vm.newnessOptions},model:{value:(_vm.newness),callback:function ($$v) {_vm.newness=$$v},expression:"newness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1776999491)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Observaciones","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Observaciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4195284284)})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Guardar novedad ")])],1)],1)],1):_c('div',[_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text text-primary"},[_vm._v(" Agregar novedad y reemplazar ")])]),_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("Importante,")]),_vm._v(" ingresa la información por la cual se va a reemplazar el serial que tiene novedad.")])])]),_c('validation-observer',{ref:"formNewnessReport"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveNewnessAndReplace($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Serial para reemplazar *","label-for":"serial"}},[_c('validation-provider',{attrs:{"name":"Serial para reemplazar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"serial"},model:{value:(_vm.replaceSerial),callback:function ($$v) {_vm.replaceSerial=$$v},expression:"replaceSerial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3608960688)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Novedad *","label-for":"newness"}},[_c('validation-provider',{attrs:{"name":"Novedad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"newness","options":_vm.newnessOptions},model:{value:(_vm.newness),callback:function ($$v) {_vm.newness=$$v},expression:"newness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1776999491)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Observaciones","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Observaciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4195284284)})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Guardar novedad ")])],1)],1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }