<template>
  <div>
    <b-nav-item
      v-if="userData.user_profile_id != 4"
      @click="newnessReportModal = true">
      <feather-icon
        size="21"
        icon="AlertTriangleIcon"
      />
      Novedad
    </b-nav-item>

  <b-modal
    v-model="newnessReportModal"
    title="Reportar novedad"
    hide-footer
    no-close-on-backdrop
    size="lg"
  >
    <b-card-text>
      Ingresa el serial al cual se le va a reportar una novedad
    </b-card-text>

    <!-- User Info: Input Fields -->

    <b-row>
      <b-col>
        <validation-observer ref="formSearchProducts">

          <b-form
            @submit.prevent="searchProduct"
            class="mb-1">
            <b-row>

              <!-- Field: Username -->
              <b-col
                cols="12"
                md="12"
              >

                <b-form-group
                    label="Serial con novedad"
                    label-for="serial"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="Serial con novedad"
                      rules="required"
                    >

                    <b-form-input
                      id="serial"
                      v-model="serial"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

              </b-col>
              
            </b-row>

            <!-- Action Buttons -->
            <b-button
              variant="outline-primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Buscar
            </b-button>

          </b-form>
        </validation-observer>
      </b-col>

      <b-col>
        <div v-if="product">
          <h4 class="text-primary">Resultado de busqueda</h4>
          <h5 class="text-info">{{ product.product_enlistment ? 'En alistamiento' : (product.product_inventory ?  'En inventario' : '') }}</h5>
          <h5>Serial: {{ product.serial }}</h5>
          <h5>Tipo de producto: {{ product.product_type.name }}</h5>
          <h5>Número de transporte: {{ product.number_order }}</h5>
          <h5>Novedad: <span :class="product.no_dispatch == 1 ? 'text-danger' : 'text-primary'">{{ product.newness ? product.newness.newness_type.name : 'Sin novedad' }}</span> </h5>
        </div>
      </b-col>
    </b-row>

    <div v-if="product" class="mt-1">
      <search-location-products-card :product="product"></search-location-products-card>
    </div>

    <div v-if="product">

      <div v-if="!product.product_enlistment">

        <div class="divider my-2">
          <div class="divider-text text-primary">
            Agregar novedad
          </div>
        </div>

        <!-- User Info: Input Fields -->
        <validation-observer ref="formNewnessReport">

          <b-form
            @submit.prevent="saveNewness">
            <b-row>

              <!-- Field: Serial -->
              <b-col
                cols="12"
                md="4"
              >

                <b-form-group
                    label="Serial *"
                    label-for="serial"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="Serial"
                      rules="required"
                    >

                    <b-form-input
                      id="serial"
                      v-model="serial"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

              </b-col>

              <!-- Field: Serial -->
              <b-col
                cols="12"
                md="4"
              >

              <b-form-group
                label="Novedad *"
                label-for="newness"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Novedad"
                    rules="required"
                  >

                  <b-form-select
                    id="newness"
                    v-model="newness"
                    :options="newnessOptions"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                
              </b-form-group>

              </b-col>

              <!-- Field: Description -->
              <b-col
                cols="12"
                md="4"
              >

                <b-form-group
                    label="Observaciones"
                    label-for="description"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="Observaciones"
                    >

                    <b-form-input
                      id="description"
                      v-model="description"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

              </b-col>
              
            </b-row>

            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Guardar novedad
            </b-button>

          </b-form>
        </validation-observer>

      </div>

      <div v-else>

        <div class="divider my-2">
          <div class="divider-text text-primary">
            Agregar novedad y reemplazar
          </div>
        </div>

        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span><strong>Importante,</strong> ingresa la información por la cual se va a reemplazar el serial que tiene novedad.</span>
          </div>
        </b-alert>

        <!-- User Info: Input Fields -->
        <validation-observer ref="formNewnessReport">

          <b-form
            @submit.prevent="saveNewnessAndReplace">
            <b-row>

              <!-- Field: Serial -->
              <b-col
                cols="12"
                md="4"
              >

                <b-form-group
                    label="Serial para reemplazar *"
                    label-for="serial"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="Serial para reemplazar"
                      rules="required"
                    >

                    <b-form-input
                      id="serial"
                      v-model="replaceSerial"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

              </b-col>

              <!-- Field: Serial -->
              <b-col
                cols="12"
                md="4"
              >

              <b-form-group
                label="Novedad *"
                label-for="newness"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Novedad"
                    rules="required"
                  >

                  <b-form-select
                    id="newness"
                    v-model="newness"
                    :options="newnessOptions"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                
              </b-form-group>

              </b-col>

              <!-- Field: Description -->
              <b-col
                cols="12"
                md="4"
              >

                <b-form-group
                    label="Observaciones"
                    label-for="description"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="Observaciones"
                    >

                    <b-form-input
                      id="description"
                      v-model="description"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

              </b-col>
              
            </b-row>

            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Guardar novedad
            </b-button>

          </b-form>
        </validation-observer>

      </div>

    </div>
   
  </b-modal>

  </div>
  
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect, BNavItem, BCardText, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { constants } from '@/helpers/constants'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SearchLocationProductsCard from '@/views/products/search-products/SearchLocationProductsCard.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BNavItem,
    BCardText,
    BAlert,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    SearchLocationProductsCard,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      userData: JSON.parse(localStorage.getItem('userData')),
      serial: '',
      sim: '',
      order: null,
      required,
      newness: null,
      description: '',
      newnessReportModal: false,
      product: null,
      replaceSerial: null,
      newnessOptions: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        },
        {
          text: 'Apps Populares',
          value: 1,
        },
        {
          text: 'No recibe carga',
          value: 2,
        },
        {
          text: 'Modo seguro',
          value: 3,
        },
        {
          text: 'Empaque no conforme',
          value: 4,
        },
        {
          text: 'Display roto',
          value: 5,
        },
        {
          text: 'Otro',
          value: 6,
        },
      ],
    }
  },
  setup() {
  },
  methods: {
    
    saveNewness() {
      event.preventDefault()
      this.$refs.formNewnessReport.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/save-newness',{
            serial: this.serial,
            newness: this.newness,
            description: this.description,
            order_id: null
          }, { headers }).then(response => {
            console.log('save-newness: ', response)

            if(response.data.status) {
              this.serial = ''
              this.sim = ''
              this.product = null
              this.newness = null
              this.description = ''
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.newnessReportModal = false

            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })

    },
    
    saveNewnessAndReplace() {
      event.preventDefault()
      this.$refs.formNewnessReport.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/save-newness-and-replace',{
            replace_serial: this.replaceSerial,
            serial: this.serial,
            newness: this.newness,
            description: this.description,
            order_id: null
          }, { headers }).then(response => {
            console.log('save-newness-and-replace: ', response)

            if(response.data.status) {
              this.serial = ''
              this.sim = ''
              this.product = null
              this.newness = null
              this.description = ''
              this.replaceSerial = null
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.newnessReportModal = false

            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })

    },

    searchProduct() {
      event.preventDefault()
      this.$refs.formSearchProducts.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/search-product',{
            serial: this.serial,
          }, { headers }).then(response => {
            console.log('search-product: ', response)

            if(response.data.status) {
              this.order = response.data.data.order
              this.product = response.data.data.product
              
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    }
  },
}
</script>
